<template>
  <ReservationForm2 />
</template>

<script>
  import ReservationForm2 from '../components/ReservationForm2'

  export default {
    name: 'Form2',

    components: {
      ReservationForm2,
    },
  }
</script>
