<template>
  <v-container >
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          src="https://www.dsautomobiles.re/content/dam/ds/master/home/DS_D1_Logoheader-Desktop-new.png"
          class="my-3"
          contain
          height="200"
        />
      </v-col>
      <v-col cols="12">
        <v-btn to="sud">
          Centre d'essais Sud du 14 septembre 2024
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn to="nord">
          Centre d'essais Nord du 14 septembre 2024
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    name: 'Home',

  }
</script>
