import Vue from 'vue'
import VueRouter from 'vue-router'
import Form1 from '../views/Form1.vue'
import Form2 from '../views/Form2.vue'
import Home from '../views/Home.vue'
import Board from '../views/Board.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/admin-board',
    name: 'Board',
    component: Board
  },
  {
    path: '/sud',
    name: 'Sud',
    component: Form1
  },
  {
    path: '/nord',
    name: 'Nord',
    component: Form2
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
