import api from "./api";


export default {
    get_vehicle_for_test_center(id) {
        return api.get(`vehicle_test_center/${id}`);
    },
    availability(data) {
        return api.post(`check-availability`, data);
    },
    createReservation(data) {
        return api.post(`create_reservation`, data);
    },
    deleteReservation(id) {
        return api.get(`reservations/${id}`)
    },
    update_arrived(id) {
        return api.get(`update_arrived/${id}`);
    },
    update_start(id) {
        return api.get(`update_start/${id}`);
    },
    update_end(id) {
        return api.get(`update_end/${id}`);
    }
}