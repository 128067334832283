<template>
  <ReservationForm />
</template>

<script>
  import ReservationForm from '../components/ReservationForm'

  export default {
    name: 'Form1',

    components: {
      ReservationForm,
    },
  }
</script>
