<template>
  <v-container>
    <v-row class="text-center" justify="center">
      <v-row justify="center">
        <v-dialog
          v-model="dialogLogin"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="black">
              <v-toolbar-title>Login</v-toolbar-title>
            </v-toolbar>
            <v-card max-width="500" class="mx-auto my-10" elevation="0">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                ></v-text-field>

                <v-btn
                  :disabled="!valid"
                  color="black"
                  class="mr-4 white--text"
                  @click="validate"
                >
                  Login
                </v-btn>
              </v-form>
            </v-card>
          </v-card>
        </v-dialog>
      </v-row>
      <v-col cols="12">
        <v-img
          src="https://www.dsautomobiles.re/content/dam/ds/master/home/DS_D1_Logoheader-Desktop-new.png"
          class="my-3"
          contain
          height="200"
        />
      </v-col>
      <v-col
        cols="12"
        v-for="(testCenter, i) in dealership.test_centers"
        :key="i"
      >
        <v-data-table
          :headers="headers"
          :items="filterReservation(testCenter.reservations)"
          sort-by="reservation_time"
          class="elevation-1"
          style="background-color: white"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title
                >Centre d'essais du
                <strong>{{ formatDate(testCenter.date) }} dans le {{ testCenter.banner_image }}</strong>
              </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <div v-if="!search">Filtre par Moniteur :</div>
              <v-toolbar-items>
                <v-btn
                  v-if="!search"
                  class="mx-1 white--text"
                  @click="search = instructor.id"
                  color="black"
                  v-for="instructor in testCenter.instructors"
                  :key="instructor.id"
                  >{{ instructor.first_name }}</v-btn
                >
                <v-btn
                  v-if="search"
                  @click="search = ''"
                  class="mx-1 white--text"
                  color="black"
                >
                  Supprimer le Filtre
                  <v-icon right> mdi-close </v-icon>
                </v-btn>
              </v-toolbar-items>

              <v-dialog v-model="dialogCustumer" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 text-center mx-auto"
                    >{{ custumer.first_name }}
                    {{ custumer.last_name }}</v-card-title
                  >
                  <v-card-text>
                    <v-chip class="ma-2" label color="black" text-color="white">
                      <v-icon left> mdi-phone </v-icon>
                      {{ custumer.phone }}</v-chip
                    >
                  </v-card-text>
                  <v-card-text>
                    <v-chip class="ma-2" label color="black" text-color="white">
                      <v-icon left>mdi-email </v-icon>
                      {{ custumer.email }}</v-chip
                    >
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeCustumer"
                      >ok</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Êtes-vous sûr(e) de vouloir supprimer cette réservation
                    ?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.client_arrived="{ item }">
            <v-chip
              :color="getColorClientArrived(item)"
              @click="changeArrive(item)"
            >
              {{ client_arrived(item) }}
            </v-chip>
          </template>
          <template v-slot:item.start_time="{ item }">
            <v-chip
              color="success"
              v-if="!item.start_time"
              @click="changeStart(item)"
            >
              Start
            </v-chip>
            <v-chip
              color="black"
              class="white--text"
              v-if="item.start_time"
              @click="changeStart(item)"
            >
              {{ item.start_time }}
            </v-chip>
          </template>
          <template v-slot:item.end_time="{ item }">
            <v-chip
              color="error"
              v-if="!item.end_time"
              @click="changeEnd(item)"
            >
              End
            </v-chip>
            <v-chip
              color="black"
              class="white--text"
              v-if="item.end_time"
              @click="changeEnd(item)"
            >
              {{ item.end_time }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item, index }">
            <v-icon small @click="deleteItem(item, index)"> mdi-delete </v-icon>
          </template>
          <template v-slot:item.client_id="{ item }">
            <v-chip @click="openModalCustumer(item)" class="font-weight-bold"
              >{{ item.client_id.first_name }}
              {{ item.client_id.last_name }}</v-chip
            >
          </template>
          <template v-slot:item.instructor_id="{ item }">
            <v-chip color="black" class="white--text"
              >{{ item.instructor_id.first_name }}
              {{ item.instructor_id.last_name }}</v-chip
            >
          </template>
          <template v-slot:no-data>
            <v-btn color="black" class="white--text" @click="initialize">
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiTestCenter from "../../service/test_center.js";
import apiReservation from "../../service/reservation.js";

export default {
  data() {
    return {
      search: "",
      custumer: {
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
      },
      custumerModel: {
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
      },
      dialogLogin: true,
      dialogCustumer: false,
      password: "Password",
      data: "DsAutomobile974",
      dataMail: "contact@cfg.re",
      show1: false,
      email: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      dialog: false,
      dialogDelete: false,
      valid: true,
      headers: [
        {
          text: "N°",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Heure", value: "reservation_time" },
        { text: "Client", value: "client_id" },
        { text: "Voiture", value: "vehicle_id.model" },
        { text: "Moniteur", value: "instructor_id" },
        { text: "Arrivé", value: "client_arrived" },
        { text: "Start", value: "start_time" },
        { text: "End", value: "end_time" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      deleteData: {
        reservation_id: -1,
        index_test_center: -1,
        index_reservation: -1,
      },
      deleteDataModel: {
        reservation_id: -1,
        index_test_center: -1,
        index_reservation: -1,
      },
      dealership_id: 1,
      dealership: {
        test_centers: [
          {
            date: "0000-00-00",
            reservations: [],
          },
        ],
      },
    };
  },

  mounted() {
    this.initialize();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    changeArrive(reservation) {
      apiReservation
        .update_arrived(reservation.id)
        .then((res) => {
          reservation.client_arrived = !reservation.client_arrived;
        })
        .catch((err) => {
          console.error(err);
          alert("un problème est survenue, merci de réessayer");
        });
    },
    changeStart(reservation) {
      apiReservation
        .update_start(reservation.id)
        .then((res) => {
          reservation.start_time = res.data.heure;
        })
        .catch((err) => {
          console.error(err);
          alert("un problème est survenue, merci de réessayer");
        });
    },
    changeEnd(reservation) {
      apiReservation
        .update_end(reservation.id)
        .then((res) => {
          reservation.end_time = res.data.heure;
        })
        .catch((err) => {
          console.error(err);
          alert("un problème est survenue, merci de réessayer");
        });
    },
    getColorClientArrived(reservation) {
      if (reservation.client_arrived) {
        return "success";
      } else {
        return "grey";
      }
    },
    client_arrived(reservation) {
      if (reservation.client_arrived) {
        return "OUI";
      } else {
        return "NON";
      }
    },
    filterReservation(reservations) {
      if (this.search) {
        return reservations.filter((reservation) => {
          return reservation.instructor_id.id == this.search;
        });
      }
      return reservations;
    },

    closeCustumer() {
      this.custumer = Object.assign({}, this.custumerModel);
      this.dialogCustumer = false;
    },
    openModalCustumer(item) {
      this.custumer = {
        first_name: item.client_id.first_name,
        last_name: item.client_id.last_name,
        phone: item.client_id.phone,
        email: item.client_id.email,
      };
      this.dialogCustumer = true;
    },
    deleteItem(item, index_reservation) {
      this.deleteData.reservation_id = item.id;
      this.deleteData.index_test_center = this.searchIndexTestCenter(
        item.test_center_id.id
      );
      this.deleteData.index_reservation = index_reservation;
      this.dialogDelete = true;
    },

    searchIndexTestCenter(id) {
      var index = null;
      this.dealership.test_centers.forEach((element, i) => {
        if (element.id == id) {
          index = i;
        }
      });
      return index;
    },

    deleteItemConfirm() {
      apiReservation
        .deleteReservation(this.deleteData.reservation_id)
        .then((res) => {
          this.dealership.test_centers[
            this.deleteData.index_test_center
          ].reservations.splice(this.deleteData.index_reservation, 1);
          this.closeDelete();
        })
        .catch((err) => {
          console.error(err);
          alert("un problème est survenue, merci de réésayer");
          this.closeDelete();
        });
    },

    closeDelete() {
      this.deleteData = Object.assign({}, this.deleteDataModel);
      this.dialogDelete = false;
    },

    formatDate(dateString) {
      const dateParts = dateString.split("-");
      return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    },
    initialize() {
      apiTestCenter
        .get_test_centers_by_dealership(this.dealership_id)
        .then((res) => {
          this.dealership = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    validate() {
      if (this.$refs.form.validate()) {
        if (this.email === this.dataMail && this.password === this.data) {
          this.dialogLogin = false;
        } else {
          alert("merci de corriger votre login");
        }
      } else {
        alert("merci de compléter le login");
      }
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>
