<template>
  <v-container>

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          src="https://www.dsautomobiles.re/content/dam/ds/master/home/DS_D1_Logoheader-Desktop-new.png"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="title font-weight-bold mb-3 white--text">
          Réservation pour le centre d'essai DS Automobiles du 14 Septembre 2024
          à Ds Automobile Nord
        </h1>
      </v-col>

      <v-col class="mb-5" cols="12">
        <v-card
          class="mx-auto"
          elevation="2"
          background-color="white"
          max-width="800px"
        >
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="first_name"
                :rules="nameRules"
                label="Prénom"
                required
              ></v-text-field>

              <v-text-field
                v-model="last_name"
                :rules="nameRules"
                label="Nom"
                required
              ></v-text-field>

              <v-text-field
                v-model="phone"
                :rules="nameRules"
                label="Téléphone"
                required
              ></v-text-field>

              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
              ></v-text-field>

              <v-select
                v-model="vehicle_id"
                :items="vehicles"
                item-text="model"
                item-value="id"
                :rules="[(v) => !!v || 'Choix du véhicule requis']"
                label="Voiture souhaitée"
                @change="instructors = null"
                required
              ></v-select>

              <v-btn v-if="!instructors" @click="checkSlotAvailable()"
                >Rechercher la disponibilité</v-btn
              >

              <v-divider class="my-4"></v-divider>

              <div v-if="instructors">
                <h3 class="mb-5">Choix de votre moniteur et de l'horaire</h3>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    xl="4"
                    v-for="(instructor, i) in instructors"
                    :key="i"
                  >
                    <v-card class="mx-auto" tile>
                      <v-list dense elevation="4" class="mb-6" rounded>
                        <v-list-item class="text-center">
                          <v-list-item-avatar class="mx-auto">
                            <v-icon> mdi-account-circle </v-icon>
                          </v-list-item-avatar>
                        </v-list-item>
                        <v-list-item class="text-center">
                          <v-list-item-content class="text-center">
                            <v-list-item-title class="text-h6 text-center">
                              {{ instructor.first_name }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item-group color="primary">
                          <v-list-item
                            v-for="(item, i) in objToArray(
                              instructor.slotAvailable
                            )"
                            :key="i"
                            @click="selectInstructorAndSlot(item, instructor)"
                          >
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
                <v-btn
                  v-if="validButton"
                  large
                  :disabled="!valid"
                  color="black"
                  class="mr-4 white--text"
                  @click="validate"
                >
                  Confirmer
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiReservation from "../../service/reservation.js";

export default {
  name: "ReservationForm",

  mounted() {
    this.init();
  },

  data: () => ({
    overlay: false,
    valid: false,
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    nameRules: [
      (v) => !!v || "Name is required",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    vehicle_id: null,
    vehicles: [],
    test_center_id: 2,
    instructor_id: null,
    reservation_time: null,
    instructors: null,
    validButton: false,
  }),
  methods: {
    init() {
      apiReservation
        .get_vehicle_for_test_center(this.test_center_id)
        .then((res) => {
          this.vehicles = res.data.data;
        })
        .catch((err) => {
          console.error(err)
          alert("une erreur s'est produite pour récupérer les voitures disponibles, merci de rééssayer")
        });
    },
    validate() {
      if(this.$refs.form.validate()) {
        this.overlay = true;
        apiReservation.createReservation({
          test_center_id: this.test_center_id,
          instructor_id: this.instructor_id,
          vehicle_id: this.vehicle_id,
          reservation_time: this.reservation_time,
          first_name: this.first_name,
          last_name: this.last_name,
          phone: this.phone,
          email: this.email,
        }).then((res) => {
          this.overlay = false;
          if(confirm("Nous avons bien enregistré votre réservation :) !")) {
            this.reset()
          } else {
            this.reset()
          }
        }).catch(err => {
          this.overlay = false;
          console.error(err);
          alert("une erreur s'est produite pour enregister votre réservation, merci de rééssayer")
          this.reset()
        });
      }

    },
    reset() {
      this.$refs.form.reset();
      this.vehicle_id= null;
      this.instructor_id = null;
      this.reservation_time = null;
      this.instructors = null;
      this.validButton = false;
    },

    objToArray(obj) {
      return Object.keys(obj).map((key) => obj[key]);
    },
    checkSlotAvailable() {
      if (!this.vehicle_id) {
        return alert("Merci de selectionner une voiture");
      }
      apiReservation
        .availability({
          test_center_id: this.test_center_id,
          vehicle_id: this.vehicle_id,
        })
        .then((res) => {
          this.instructors = res.data;
        }).catch(err => {
          console.error(err) 
          alert("une erreur s'est produite pour vérifier la disponibilité, merci de rééssayer")
          this.reset()
        });
    },
    selectInstructorAndSlot(slot, instructor) {
      if (this.instructor_id) {
        this.instructor_id = null;
        this.reservation_time = null;
        this.instructors = null;
        this.validButton = false;
        return;
      }
      this.reservation_time = slot;
      this.instructor_id = instructor.id;
      this.validButton = true;
    },
  },
};
</script>
